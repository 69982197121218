<template>
  <div class="student-dashboard">
    <div class="dash-wrapper container">
      <div class="dash-content">
        <div class="dash-header">
          <ul class="list-unstyled dashNav-wrap">
            <li>
              <router-link
                :to="{ name: 'Dashboard' }"
                class="dash-nav"
                :class="$route.name === 'Dashboard' ? 'selected' : ''"
                >Dashboard
              </router-link>
            </li>
            <li>
              <router-link
                :to="{ name: 'MyCourses' }"
                class="dash-nav"
                :class="
                  $route.name === 'MyCourses' ||
                  $route.name === 'MyCourseDetails'
                    ? 'selected'
                    : ''
                "
                >Courses
              </router-link>
            </li>
            <li>
              <router-link
                :to="{ name: 'MyEvents' }"
                class="dash-nav"
                :class="$route.name === 'MyEvents' ? 'selected' : ''"
                >Events
              </router-link>
            </li>
            <!-- <li>
              <router-link
                :to="{ name: 'MyAcademics' }"
                class="dash-nav"
                :class="$route.name === 'MyAcademics' ? 'selected' : ''"
                >Academics
              </router-link>
            </li> -->
            <li>
              <a
                class="dash-nav"
                @click="
                  redirectLink('https://content.vblive.co/login/index.php')
                "
                id="content_protal"
              >
                Content Portal
              </a>
            </li>
            <li>
              <router-link
                :to="{ name: 'MyDiscussionBoard' }"
                class="dash-nav"
                :class="$route.name === 'MyDiscussionBoard' ? 'selected' : ''"
                >Discussion Board
              </router-link>
            </li>
            <li>
              <router-link
                :to="{ name: 'MyFeedback' }"
                class="dash-nav"
                :class="$route.name === 'MyFeedback' ? 'selected' : ''"
                >Feedback
              </router-link>
            </li>
            <li>
              <router-link
                :to="{ name: 'Profile' }"
                class="dash-nav"
                :class="$route.name === 'Profile' ? 'selected' : ''"
                >Profile
              </router-link>
            </li>
          </ul>
        </div>
        <div class="dash-body">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MeetingService from "@/services/MeetingService";
export default {
  name: "CourseDetail",
  components: {},
  created() {
    if (this.$route.query.from == "zoom-error") {
      var currentUrl = window.location.href;
      var dashboardUrl = currentUrl.split("?")[0];
      window.location.replace(dashboardUrl);
    }
    if (this.$route.query.from == "zoom-success") {
      this.exitMeeting();
    }
  },
  methods: {
    exitMeeting() {
      let params = {
        schedule_id: this.$route.query.schedule,
      };
      MeetingService.exitMeeting(params)
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    redirectLink(link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<style lang="scss">
@import "@/style/student-dashboard.scss";
#content_protal {
  cursor: pointer;
}
</style>
